import React from 'react';
import {useDocTitle} from '../components/CustomHook';   
//import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
//import Hero from '../components/Hero';
//import Intro from '../components/Intro';
//import Portfolio from '../components/Portfolio';
//import Services from '../components/Services';
import NavBar from '../components/Navbar/NavBar';
import ServiceVertical from '../components/ServiceVertical';
//import ResumeData from './ResumeData';


const Home = () => {
    useDocTitle('Aegisoft | Soluciones Integrales')
    return (
        <>
            <NavBar />
            <ServiceVertical/>
            <Cta/>
            <Footer />
        </>

    )
}

export default Home;

