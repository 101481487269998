import React from 'react';
//import kws from '../images/clients/kws.png';
//import geps from '../images/clients/geps.png';
//import protergia from '../images/clients/protergia.png';
import conde from '../images/clients/conde.jpg';
import ecofluvial from '../images/clients/ecofluvial.jpg';
import consultora from '../images/clients/enlaceconsultora.jpg';
import inpromed from '../images/clients/inpromed.jpg';
import marketquinto from '../images/clients/marketquinto.jpg';
import provicon from '../images/clients/provicon.jpg';
import sep from '../images/clients/sep.jpg';
import tecnocable from '../images/clients/tecnocable.jpg';
import volere from '../images/clients/volere.jpg';


const clientImage = {
    height: '15rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <div  className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-gray-600 uppercase font-bold">Nuestros clientes</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-gray-600'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-gray-600">Desarrollamos la identidad en diversos canales de comunicación.</h2>
                </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3 my-4 py-4">
                        <div>
                            <a href='https://www.aegisoft.com.py'>
                                <div style={clientImage} className= "overflow-hidden  flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6">
                                    <img src={conde} alt="Conde SA" />                           
                                </div>
                            </a>
                        </div>
                        <div>
                            <a href='https://www.aegisoft.com.py'>
                                <div style={clientImage} className="overflow-hidden  flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                                    <img src={ecofluvial} alt="client"  />                            
                                </div>                                 
                            </a>
                        </div>
                        <div>
                            <a href='https://www.aegisoft.com.py'>
                                <div style={clientImage}  className="overflow-hidden  flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                                    <img src={consultora} alt="client" />                            
                                </div>
                            </a>
                        </div>
                        
                        
                        {/* <div style={clientImage}  className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={geps} alt="client" />                            
                        </div> */}

                                           
                    </div>
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3 my-4 py-4">
                        <div>
                            <a href='https://www.aegisoft.com.py'>
                                <div style={clientImage} className= "overflow-hidden  flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6">
                                    <img src={inpromed} alt="client" />                           
                                </div>
                            </a>
                        </div>
                        <div>
                            <a href='https://www.aegisoft.com.py'>
                                <div style={clientImage} className="overflow-hidden  flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                                    <img src={marketquinto} alt="client"  />                            
                                </div>                                 
                            </a>
                        </div>
                        <div>
                            <a href='https://www.aegisoft.com.py'>
                                <div style={clientImage}  className="overflow-hidden  flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                                    <img src={provicon} alt="client" />                            
                                </div>
                            </a>
                        </div>
                        
                        
                        {/* <div style={clientImage}  className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={geps} alt="client" />                            
                        </div> */}

                                           
                    </div>
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3 my-4 py-4">
                        <div>
                            <a href='https://www.aegisoft.com.py'>
                                <div style={clientImage} className= "overflow-hidden  flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6">
                                    <img src={sep} alt="client" />                           
                                </div>
                            </a>
                        </div>
                        <div>
                            <a href='https://www.aegisoft.com.py'>
                                <div style={clientImage} className="overflow-hidden  flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                                    <img src={tecnocable} alt="client"  />                            
                                </div>                                 
                            </a>
                        </div>
                        <div>
                            <a href='https://www.aegisoft.com.py'>
                                <div style={clientImage}  className="overflow-hidden  flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                                    <img src={volere} alt="client" />                            
                                </div>
                            </a>
                        </div>
                        
                        
                        {/* <div style={clientImage}  className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={geps} alt="client" />                            
                        </div> */}

                                           
                    </div>
                </div>
                
            </section>
        </div>
    )
}

export default Clients;