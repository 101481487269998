import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-green-400 hover:text-green-600" smooth to="/#Intro">
                Quienes somos
            </HashLink>
            <HashLink className="px-4 font-extrabold text-green-400 hover:text-green-600" smooth to="/#services">
                Servicios
            </HashLink>
            <HashLink className="px-4 font-extrabold text-green-400 hover:text-green-600" to="/#portafolio">
                Portafolio
            </HashLink>
            <HashLink className="px-4 font-extrabold text-green-400 hover:text-green-600" to="/contact">
                Contáctenos
            </HashLink>
            <Link className="text-white bg-green-600 hover:bg-green-400 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo">
                Demo de nuestros productos
            </Link>
        </>
    )
}

export default NavLinks;