import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
      <>
        <div className="my-4 py-4" id="portfolio">
          <h2 className="my-2 text-center text-3xl text-green-600 uppercase font-bold">
            Productos
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-green-600 mb-6"></div>
          </div>
          <div className="mb-6">
            <h2 className=" mx-12 text-center text-xl lg:text-2xl font-semibold text-green-600">
              Creamos experiencias que transforman marcas, crecemos las empresas
              y hacemos más fácil la vida de las personas.
            </h2>
          </div>

          <div className="px-4" data-aos="fade-down" data-aos-delay="600">
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                    Sistema de gestión empresarial
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48">
                    Nuestro sistema de gestión empresarial es un sistema robusto
                    y fácilmente escalable que optimiza las operaciones
                    comerciales, aumenta la eficiencia y, en última instancia,
                    impulsa el crecimiento y la rentabilidad para su negocio.
                    Con características como la gestión de empleados, el control
                    financiero y muchos más módulos para administrar
                    efectivamente todos los aspectos de una empresa. Todos los
                    datos se almacenan en la nube y, como tal, se puede acceder
                    fácilmente desde todos los dispositivos y desde cualquier
                    ubicación.
                  </p>
                  <div className="flex justify-center my-4">
                    <Link
                      to="/productos"
                      className="text-white bg-green-600 hover:bg-green-400 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                    >
                      Solicitar demostración
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                    Sistema de Inventario y Facturacion{" "}
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48">
                    Nuestro portal de gestión escolar que abarca es la única
                    solución necesaria para cualquier institución. El portal de
                    gestión escolar (SMP) es una herramienta que puede ayudar a
                    las instituciones educativas de todo tipo a administrar sus
                    tareas administrativas, automatizar procesos y racionalizar
                    la comunicación entre maestros, estudiantes, padres y
                    administradores. El software se puede utilizar para
                    administrar todos los aspectos de las operaciones escolares,
                    incluida la inscripción de los estudiantes, la asistencia,
                    las calificaciones, los horarios y más.
                  </p>
                  <div className="flex justify-center my-4">
                    <Link
                      to="/productos"
                      className="text-white bg-green-600 hover:bg-green-400 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                    >
                      Solicitar demostración
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </div>

                  {/* <ul className="border-t border-gray-300 py-8 space-y-6">
                                    <li className="flex items-center space-x-2 px-8">
                                        <span className="bg-blue-600 rounded-full p-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </span>
                                        <span className="text-gray-600 capitalize">Free Setup Guidance</span>
                                    </li>
                                </ul> */}
                </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                    Sistema de gestión de RRHH
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48">
                    El sistema de gestión de la nómina automatiza el proceso de
                    pagos de empleados para empresas y organizaciones de
                    diferentes tamaños.Ayuda a garantizar que a sus empleados
                    reciban pagos con precisión y a tiempo, al tiempo que reduce
                    el tiempo y el esfuerzo requeridos para administrar la
                    nómina manualmente, con este sistema en su lugar, disfruta
                    de beneficios como: Mayor precisión en los pagos de los
                    empleados.También puede integrarse en un sistema de gestión
                    existente a través de API bien estructuradas.
                  </p>
                  <div className="flex justify-center my-4">
                    <Link
                      to="/productos"
                      className="text-white bg-green-600 hover:bg-green-400 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                    >
                      Solicitar demostración
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                    Sistema de gestión de proyectos
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48">
                    Nuestro sistema de gestión de eventos ayuda a administrar
                    diferentes tipos de eventos, ya sea bodas, entierros o
                    cualquier tipo de evento.Con un menú fácilmente
                    personalizable, puede definir su experiencia de usuario y
                    hacer que su evento sea totalmente personalizable y
                    memorable. Con los casos de uso ya registrados para una gran
                    cantidad de eventos, podemos asegurarle una plataforma de
                    gestión de eventos sin estrés.
                  </p>
                  <div className="flex justify-center my-4">
                    <Link
                      to="/productos"
                      className="text-white bg-green-600 hover:bg-green-400 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                    >
                      Solicitar demostración
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Portfolio;