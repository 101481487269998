/** @format */

import React from "react";
import brain from "../images/assets/brain.png";
import engineer from "../images/assets/engineer.png";
import product from "../images/assets/product.png";
import ui from "../images/assets/ui.png";
import { Link } from "react-router-dom";
import { useDocTitle } from "../components/CustomHook";

const ServiceVertical = () => {
  useDocTitle("Aegisoft | Productos y Servicios");
  return (
    <>
      <div
        className="m-auto max-w-6xl p-2 md:p-12 h-5/6"
        id="ServiceVertical"
      ></div>
      <div className="my-4 py-4">
        <h2 className="my-2 text-center text-3xl text-green-600 uppercase font-bold">
          Servicios
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-green-600"></div>
        </div>
        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-green-600">
          Estamos profundamente comprometidos con el crecimiento y el éxito de
          nuestros clientes.
        </h2>
      </div>
      <div className=" m-auto max-w-6xl p-2 md:p-12 h-5/6">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" className="rounded-t float-right" src={brain} />
          </div>
          <div
            className="flex-col my-6 text-center lg:text-left lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-3xl  text-green-600 font-bold">
              Desarrollo web
            </h3>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                Nuestro equipo tiene un gran desarrollo de software y está listo
                para ayudar a desarrollar las aplicaciones de su elección.
              </p>
            </div>

            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                Asumimos la responsabilidad de crear soluciones de software
                personalizadas que atienden la automatización de los procesos de
                su negocio y mejorar la eficiencia.
              </p>
            </div>
            <Link
              to="/get-demo"
              className="text-white bg-green-600 hover:bg-green-400 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
            >
              Contáctenos
              <svg
                className="w-4 h-4 ml-1 group-hover: translate-x-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div
            className="flex-col my-6 text-center lg:text-left  lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-3xl  text-green-600 font-bold">
              Aplicaciones móviles
            </h3>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                Nuestro equipo tiene un gran desarrollo de software y está listo
                para ayudar a desarrollar las aplicaciones de su elección.
              </p>
            </div>

            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                Asumimos la responsabilidad de crear soluciones de software
                personalizadas que atienden la automatización de los procesos de
                su negocio y mejorar la eficiencia.
              </p>
            </div>
            <Link
              to="/get-demo"
              className="text-white bg-green-600 hover:bg-green-400 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
            >
              Contáctenos
              <svg
                className="w-4 h-4 ml-1 group-hover: translate-x-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img
              alt="card img"
              className="rounded-t float-right"
              src={engineer}
            />
          </div>
        </div>
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img
              alt="card img"
              className="rounded-t float-right"
              src={product}
            />
          </div>
          <div
            className="flex-col my-6 text-center lg:text-left  lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-3xl  text-green-600 font-bold">
              Dominio y alojamiento
            </h3>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                Nuestro equipo tiene un gran desarrollo de software y está listo
                para ayudar a desarrollar las aplicaciones de su elección.
              </p>
            </div>

            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                Asumimos la responsabilidad de crear soluciones de software
                personalizadas que atienden la automatización de los procesos de
                su negocio y mejorar la eficiencia.
              </p>
            </div>
            <Link
              to="/get-demo"
              className="text-white bg-green-600 hover:bg-green-400 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
            >
              Contáctenos
              <svg
                className="w-4 h-4 ml-1 group-hover: translate-x-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div
            className="flex-col my-6 text-center lg:text-left  lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-3xl  text-green-600 font-bold">
              Consultoria IT
            </h3>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                Nuestro equipo tiene un gran desarrollo de software y está listo
                para ayudar a desarrollar las aplicaciones de su elección.
              </p>
            </div>

            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                Asumimos la responsabilidad de crear soluciones de software
                personalizadas que atienden la automatización de los procesos de
                su negocio y mejorar la eficiencia.
              </p>
            </div>
            <Link
              to="/get-demo"
              className="text-white bg-green-600 hover:bg-green-400 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
            >
              Contáctenos
              <svg
                className="w-4 h-4 ml-1 group-hover: translate-x-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" className="rounded-t float-right" src={ui} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceVertical;
